#SECTION1 {
  height: 663px;
}

#IMAGE36,
#IMAGE36 > .ladi-image > .ladi-image-background,
#IMAGE39,
#IMAGE39 > .ladi-image > .ladi-image-background,
#IMAGE38,
#IMAGE38 > .ladi-image > .ladi-image-background,
#IMAGE37,
#IMAGE37 > .ladi-image > .ladi-image-background,
#IMAGE4,
#IMAGE4 > .ladi-image > .ladi-image-background,
#IMAGE49,
#IMAGE49 > .ladi-image > .ladi-image-background,
#IMAGE43,
#IMAGE43 > .ladi-image > .ladi-image-background,
#IMAGE42,
#IMAGE42 > .ladi-image > .ladi-image-background,
#IMAGE41,
#IMAGE41 > .ladi-image > .ladi-image-background,
#IMAGE40,
#IMAGE40 > .ladi-image > .ladi-image-background,
#IMAGE46,
#IMAGE46 > .ladi-image > .ladi-image-background,
#IMAGE45,
#IMAGE45 > .ladi-image > .ladi-image-background,
#IMAGE44,
#IMAGE44 > .ladi-image > .ladi-image-background,
#IMAGE48,
#IMAGE48 > .ladi-image > .ladi-image-background,
#IMAGE90,
#IMAGE90 > .ladi-image > .ladi-image-background,
#IMAGE16,
#IMAGE16 > .ladi-image > .ladi-image-background {
  width: 420px;
  height: 420px;
}

#IMAGE47,
#IMAGE47 > .ladi-image > .ladi-image-background {
  width: 420px;
  height: 518px;
}

#IMAGE97,
#IMAGE97 > .ladi-image > .ladi-image-background,
#IMAGE98,
#IMAGE98 > .ladi-image > .ladi-image-background {
  width: 420px;
  height: 920px;
}

#IMAGE36 {
  top: 647px;
  left: 0px;
}

#IMAGE36 > .ladi-image > .ladi-image-background,
#BOX1,
#SUPBOX1,
#IMAGE1 > .ladi-image > .ladi-image-background,
#IMAGE3 > .ladi-image > .ladi-image-background,
#IMAGE58 > .ladi-image > .ladi-image-background,
#IMAGE39 > .ladi-image > .ladi-image-background,
#IMAGE38 > .ladi-image > .ladi-image-background,
#IMAGE37 > .ladi-image > .ladi-image-background,
#IMAGE23 > .ladi-image > .ladi-image-background,
#IMAGE21 > .ladi-image > .ladi-image-background,
#IMAGE20 > .ladi-image > .ladi-image-background,
#IMAGE19 > .ladi-image > .ladi-image-background,
#IMAGE17 > .ladi-image > .ladi-image-background,
#IMAGE4 > .ladi-image > .ladi-image-background,
#IMAGE18 > .ladi-image > .ladi-image-background,
#IMAGE24 > .ladi-image > .ladi-image-background,
#IMAGE22 > .ladi-image > .ladi-image-background,
#IMAGE25 > .ladi-image > .ladi-image-background,
#IMAGE49 > .ladi-image > .ladi-image-background,
#IMAGE5 > .ladi-image > .ladi-image-background,
#IMAGE6 > .ladi-image > .ladi-image-background,
#IMAGE7 > .ladi-image > .ladi-image-background,
#HEADLINE15,
#HEADLINE18,
#IMAGE43 > .ladi-image > .ladi-image-background,
#IMAGE42 > .ladi-image > .ladi-image-background,
#IMAGE41 > .ladi-image > .ladi-image-background,
#IMAGE40 > .ladi-image > .ladi-image-background,
#IMAGE27 > .ladi-image > .ladi-image-background,
#IMAGE26 > .ladi-image > .ladi-image-background,
#IMAGE8 > .ladi-image > .ladi-image-background,
#IMAGE9 > .ladi-image > .ladi-image-background,
#HEADLINE21,
#HEADLINE26,
#LINE4,
#LINE6,
#HEADLINE40,
#HEADLINE44,
#IMAGE10 > .ladi-image > .ladi-image-background,
#IMAGE11 > .ladi-image > .ladi-image-background,
#IMAGE12 > .ladi-image > .ladi-image-background,
#IMAGE13 > .ladi-image > .ladi-image-background,
#IMAGE46 > .ladi-image > .ladi-image-background,
#IMAGE45 > .ladi-image > .ladi-image-background,
#IMAGE44 > .ladi-image > .ladi-image-background,
#HEADLINE57,
#IMAGE48 > .ladi-image > .ladi-image-background,
#IMAGE32 > .ladi-image > .ladi-image-background,
#IMAGE29 > .ladi-image > .ladi-image-background,
#IMAGE33 > .ladi-image > .ladi-image-background,
#IMAGE28 > .ladi-image > .ladi-image-background,
#IMAGE31 > .ladi-image > .ladi-image-background,
#IMAGE34 > .ladi-image > .ladi-image-background,
#IMAGE35 > .ladi-image > .ladi-image-background,
#IMAGE47 > .ladi-image > .ladi-image-background,
#IMAGE14 > .ladi-image > .ladi-image-background,
#BOX16,
#BOX116,
#BOX117,
#BOX118,
#BOX119,
#IMAGE16 > .ladi-image > .ladi-image-background,
#POPUP1,
#IMAGE50 > .ladi-image > .ladi-image-background,
#IMAGE51 > .ladi-image > .ladi-image-background,
#IMAGE55,
#IMAGE55 > .ladi-image > .ladi-image-background,
#IMAGE57 > .ladi-image > .ladi-image-background,
#IMAGE59 > .ladi-image > .ladi-image-background,
#IMAGE90 > .ladi-image > .ladi-image-background,
#IMAGE91 > .ladi-image > .ladi-image-background,
#IMAGE97 > .ladi-image > .ladi-image-background,
#IMAGE98 > .ladi-image > .ladi-image-background,
#HEADLINE68 {
  top: 0px;
  left: 0px;
}

#IMAGE36 > .ladi-image > .ladi-image-background,
#IMAGE39 > .ladi-image > .ladi-image-background,
#IMAGE38 > .ladi-image > .ladi-image-background,
#IMAGE37 > .ladi-image > .ladi-image-background,
#IMAGE49 > .ladi-image > .ladi-image-background,
#IMAGE43 > .ladi-image > .ladi-image-background,
#IMAGE42 > .ladi-image > .ladi-image-background,
#IMAGE41 > .ladi-image > .ladi-image-background,
#IMAGE40 > .ladi-image > .ladi-image-background,
#IMAGE46 > .ladi-image > .ladi-image-background,
#IMAGE45 > .ladi-image > .ladi-image-background,
#IMAGE44 > .ladi-image > .ladi-image-background,
#IMAGE48 > .ladi-image > .ladi-image-background,
#IMAGE47 > .ladi-image > .ladi-image-background,
#IMAGE90 > .ladi-image > .ladi-image-background,
#IMAGE97 > .ladi-image > .ladi-image-background,
#IMAGE98 > .ladi-image > .ladi-image-background,
#IMAGE50 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s750x750/6322a62f2dad980013bb5005/bd95284c466fe631bf7e-20240706091144-0xksq.jpg');
}

#IMAGE36 > .ladi-image,
#IMAGE38 > .ladi-image,
#IMAGE37 > .ladi-image,
#IMAGE43 > .ladi-image,
#IMAGE42 > .ladi-image,
#IMAGE41 > .ladi-image,
#IMAGE40 > .ladi-image,
#IMAGE46 > .ladi-image,
#IMAGE45 > .ladi-image,
#IMAGE44 > .ladi-image,
#IMAGE48 > .ladi-image,
#IMAGE47 > .ladi-image,
#IMAGE90 > .ladi-image,
#IMAGE97 > .ladi-image,
#IMAGE98 > .ladi-image,
#IMAGE50 > .ladi-image {
  filter: brightness(104%);
}

#BOX1 {
  width: 420px;
  height: 647px;
}

#SUPBOX1 {
  width: 420px;
  height: 647px;
}

#SUPBOX1 > .ladi-box,
#BOX1 > .ladi-box,
#BOX2 > .ladi-box,
#BOX3 > .ladi-box,
#IMAGE24 > .ladi-image,
#IMAGE26 > .ladi-image,
#BOX5 > .ladi-box,
#BOX6 > .ladi-box,
#BOX7 > .ladi-box,
#BOX8 > .ladi-box,
#BOX9 > .ladi-box,
#BOX10 > .ladi-box,
#BOX11 > .ladi-box,
#BOX12 > .ladi-box,
#BOX13 > .ladi-box,
#BOX14 > .ladi-box,
#BOX15 > .ladi-box,
#BOX18 > .ladi-box {
  border-radius: 0px;
}

#BOX1 > .ladi-box {
  background-image: url('/public/images/anh-phong.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 0%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#SUPBOX1 > .ladi-box.active {
  z-index: 0; /* Hiển thị SUPBOX1 sau khi BOX1 hoàn tất */
  visibility: visible;
}

#SUPBOX1 > .ladi-box:hover,
#BOX1 > .ladi-box:hover,
#HEADLINE3 > .ladi-headline:hover,
#HEADLINE4 > .ladi-headline:hover,
#HEADLINE5 > .ladi-headline:hover,
#BOX2 > .ladi-box:hover,
#HEADLINE9 > .ladi-headline:hover,
#BOX3 > .ladi-box:hover,
#HEADLINE13 > .ladi-headline:hover,
#BOX5 > .ladi-box:hover,
#BOX6 > .ladi-box:hover,
#BOX7 > .ladi-box:hover,
#BOX8 > .ladi-box:hover,
#BOX9 > .ladi-box:hover,
#BOX10 > .ladi-box:hover,
#BOX11 > .ladi-box:hover,
#BOX12 > .ladi-box:hover,
#BOX13 > .ladi-box:hover,
#BOX14 > .ladi-box:hover,
#BOX15 > .ladi-box:hover,
#BUTTON2 > .ladi-button:hover,
#BUTTON_TEXT2 > .ladi-headline:hover,
#BOX16 > .ladi-box:hover,
#BOX116 > .ladi-box:hover,
#BOX117 > .ladi-box:hover,
#BOX118 > .ladi-box:hover,
#BOX119 > .ladi-box:hover,
#HEADLINE61 > .ladi-headline:hover,
#HEADLINE161 > .ladi-headline:hover,
#HEADLINE162 > .ladi-headline:hover,
#HEADLINE163 > .ladi-headline:hover,
#HEADLINE164 > .ladi-headline:hover,
#BOX17 > .ladi-box:hover,
#HEADLINE64 > .ladi-headline:hover,
#HEADLINE65 > .ladi-headline:hover,
#HEADLINE66 > .ladi-headline:hover,
#HEADLINE72 > .ladi-headline:hover,
#BOX18 > .ladi-box:hover,
#HEADLINE68 > .ladi-headline:hover,
#HEADLINE69 > .ladi-headline:hover,
#HEADLINE70 > .ladi-headline:hover,
#HEADLINE71 > .ladi-headline:hover {
  opacity: 1;
}

#HEADLINE3,
#HEADLINE5,
#HEADLINE6,
#HEADLINE16,
#HEADLINE18,
#HEADLINE61,
#HEADLINE162,
#HEADLINE163,
#HEADLINE164,
#HEADLINE161 {
  width: 200px;
}

#HEADLINE164 {
  width: 300px;
}

#HEADLINE3 {
  top: 441.5px;
  left: 50px;
}

#HEADLINE3 > .ladi-headline,
#HEADLINE5 > .ladi-headline {
  font-family: Betalisa;
  font-size: 45px;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  text-align: center;
}

#HEADLINE3.ladi-animation > .ladi-headline,
#GROUP17.ladi-animation > .ladi-group,
#HEADLINE6.ladi-animation > .ladi-headline,
#HEADLINE7.ladi-animation > .ladi-headline,
#HEADLINE8.ladi-animation > .ladi-headline,
#HEADLINE9.ladi-animation > .ladi-headline,
#HEADLINE11.ladi-animation > .ladi-headline,
#HEADLINE12.ladi-animation > .ladi-headline,
#HEADLINE13.ladi-animation > .ladi-headline,
#HEADLINE14.ladi-animation > .ladi-headline,
#HEADLINE17.ladi-animation > .ladi-headline,
#GROUP2.ladi-animation > .ladi-group,
#GROUP3.ladi-animation > .ladi-group,
#HEADLINE30.ladi-animation > .ladi-headline,
#HEADLINE31.ladi-animation > .ladi-headline,
#HEADLINE32.ladi-animation > .ladi-headline,
#HEADLINE33.ladi-animation > .ladi-headline,
#GROUP18.ladi-animation > .ladi-group,
#HEADLINE43.ladi-animation > .ladi-headline,
#GROUP8.ladi-animation > .ladi-group,
#GROUP21.ladi-animation > .ladi-group,
#HEADLINE53.ladi-animation > .ladi-headline,
#HEADLINE55.ladi-animation > .ladi-headline,
#GROUP10.ladi-animation > .ladi-group,
#HEADLINE35.ladi-animation > .ladi-headline,
#BOX8.ladi-animation > .ladi-box,
#HEADLINE60.ladi-animation > .ladi-headline,
#HEADLINE98.ladi-animation > .ladi-headline,
#HEADLINE101.ladi-animation > .ladi-headline,
#FORM2.ladi-animation > .ladi-form,
#BUTTON2.ladi-animation > .ladi-button,
#GROUP14.ladi-animation > .ladi-group,
#GROUP99.ladi-animation > .ladi-group,
#GROUP199.ladi-animation > .ladi-group,
#GROUP200.ladi-animation > .ladi-group,
#GROUP201.ladi-animation > .ladi-group,
#GROUP202.ladi-animation > .ladi-group,
#HEADLINE62.ladi-animation > .ladi-headline,
#HEADLINE99.ladi-animation > .ladi-headline,
#HEADLINE100.ladi-animation > .ladi-headline,
#HEADLINE102.ladi-animation > .ladi-headline,
#GROUP15.ladi-animation > .ladi-group {
  animation-name: fadeInUp;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

#GROUP17 {
  width: 262px;
  height: 64px;
  top: 495.5px;
  left: 113px;
}

#HEADLINE4 {
  width: 128px;
  top: 10px;
  left: 0px;
}

#HEADLINE4 > .ladi-headline {
  font-family: Betalisa;
  font-size: 24.3px;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  text-align: center;
}

#HEADLINE5 {
  top: 0px;
  left: 62px;
}

#IMAGE1,
#IMAGE1 > .ladi-image > .ladi-image-background {
  width: 112px;
  height: 112px;
}

#IMAGE1 {
  top: 368.5px;
  left: -35px;
}

#IMAGE1 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s450x450/6322a62f2dad980013bb5005/may-removebg-preview-20240627072149-8a2ru.png');
}

#IMAGE1.ladi-animation > .ladi-image,
#HEADLINE20.ladi-animation > .ladi-headline,
#GROUP19.ladi-animation > .ladi-group,
#BOX6.ladi-animation > .ladi-box,
#BOX7.ladi-animation > .ladi-box,
#BOX11.ladi-animation > .ladi-box,
#BOX13.ladi-animation > .ladi-box,
#BOX15.ladi-animation > .ladi-box {
  animation-name: fadeInLeft;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

#IMAGE3,
#IMAGE3 > .ladi-image > .ladi-image-background {
  width: 136.5px;
  height: 136.5px;
}

#IMAGE3 {
  top: 526.5px;
  left: 313px;
}

#IMAGE3 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s450x450/6322a62f2dad980013bb5005/untitled-1-20240705083847-n_sae.png');
}

#IMAGE3.ladi-animation > .ladi-image,
#GROUP20.ladi-animation > .ladi-group,
#BOX9.ladi-animation > .ladi-box,
#BOX10.ladi-animation > .ladi-box,
#BOX12.ladi-animation > .ladi-box,
#BOX14.ladi-animation > .ladi-box {
  animation-name: fadeInRight;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

#HEADLINE6 {
  top: 600.25px;
  left: 110px;
}

#HEADLINE6 > .ladi-headline {
  font-family: CormorantGaramond-Medium;
  font-size: 18px;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  letter-spacing: 3px;
  text-align: center;
}

#IMAGE58,
#IMAGE58 > .ladi-image > .ladi-image-background {
  width: 37px;
  height: 37px;
}

#IMAGE58 {
  top: 5px;
  left: auto;
  right: 5px;
  bottom: auto;
  position: fixed;
  z-index: 90000050;
  margin-right: calc(50% - 210px);
}

#IMAGE58 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s350x350/6322a62f2dad980013bb5005/play_pause_fast_forward_stop_in_circles-20241114040800-0qpq9.png');
}

#IMAGE58.ladi-animation > .ladi-image,
#HEADLINE18.ladi-animation > .ladi-headline,
#HEADLINE67.ladi-animation > .ladi-headline {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#SECTION2 {
  height: 1471.2px;
}

#IMAGE39 {
  top: 1070px;
  left: 0px;
}

#IMAGE39 > .ladi-image {
  filter: brightness(103%);
}

#IMAGE38 {
  top: 824px;
  left: 0px;
}

#IMAGE37 {
  top: 404px;
  left: 0px;
}

#IMAGE23,
#IMAGE23 > .ladi-image > .ladi-image-background {
  width: 102.5px;
  height: 102.5px;
}

#IMAGE23 {
  top: 688.6px;
  left: 341.5px;
}

#IMAGE23 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s450x450/6322a62f2dad980013bb5005/21-20240706085438-sxxkw.png');
}

#IMAGE23 > .ladi-image,
#IMAGE22 > .ladi-image,
#IMAGE33 > .ladi-image,
#IMAGE51 > .ladi-image {
  opacity: 0.15;
}

#IMAGE21,
#IMAGE21 > .ladi-image > .ladi-image-background {
  width: 152.5px;
  height: 152.5px;
}

#IMAGE21 {
  top: 796px;
  left: 144px;
}

#IMAGE21 > .ladi-image > .ladi-image-background,
#IMAGE28 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s500x500/6322a62f2dad980013bb5005/19-20240706085151-h6xzr.png');
}

#IMAGE21 > .ladi-image {
  transform: rotate(13deg);
  opacity: 0.37;
}

#IMAGE20,
#IMAGE20 > .ladi-image > .ladi-image-background {
  width: 203px;
  height: 203px;
}

#IMAGE20 {
  top: 730px;
  left: 254.5px;
}

#IMAGE20 > .ladi-image > .ladi-image-background,
#IMAGE29 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s550x550/6322a62f2dad980013bb5005/19-20240706085151-h6xzr.png');
}

#IMAGE20 > .ladi-image {
  transform: rotate(-10deg);
  opacity: 0.37;
}

#IMAGE19,
#IMAGE19 > .ladi-image > .ladi-image-background {
  width: 137px;
  height: 137px;
}

#IMAGE19 {
  top: 362px;
  left: 271px;
}

#IMAGE19 > .ladi-image > .ladi-image-background,
#IMAGE18 > .ladi-image > .ladi-image-background,
#IMAGE51 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s450x450/6322a62f2dad980013bb5005/18-20240706084907-rk017.png');
}

#IMAGE19 > .ladi-image,
#IMAGE18 > .ladi-image,
#IMAGE24 > .ladi-image,
#IMAGE26 > .ladi-image {
  opacity: 0.1;
}

#IMAGE17,
#IMAGE17 > .ladi-image > .ladi-image-background {
  width: 140px;
  height: 140px;
}

#IMAGE17 {
  top: 218px;
  left: 286px;
}

#IMAGE17 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s450x450/6322a62f2dad980013bb5005/17-20240706084654-5wcki.png');
}

#IMAGE17 > .ladi-image,
#IMAGE27 > .ladi-image {
  opacity: 0.25;
}

#IMAGE4 {
  top: -47px;
  left: 0px;
}

#IMAGE4 > .ladi-image > .ladi-image-background {
  background-image: url('/public/chu-hy.png');
}

#HEADLINE7 {
  width: 375px;
  top: 17.5px;
  left: 22.5px;
}

#HEADLINE7 > .ladi-headline {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  line-height: 2;
  color: rgb(0, 0, 0);
  text-align: center;
}

#BOX2,
#BOX3 {
  width: 340px;
  height: 504px;
}

#BOX2 {
  top: 292px;
  left: 0px;
}

#BOX2 > .ladi-box {
  background-image: url('/public/images/co-dau.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 0%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#HEADLINE8,
#HEADLINE12,
#HEADLINE29,
#HEADLINE30 {
  width: 150px;
}

#HEADLINE8 {
  top: 254.5px;
  left: 15px;
}

#HEADLINE8 > .ladi-headline {
  font-family: CormorantGaramond-Bold;
  font-size: 19px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: left;
}

#HEADLINE9,
#HEADLINE13,
#HEADLINE31 {
  width: 247px;
}

#HEADLINE9 {
  top: 303.5px;
  left: 7.5px;
}

#HEADLINE9 > .ladi-headline {
  font-family: Betalisa;
  font-size: 32px;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  text-align: center;
}

#HEADLINE11,
#HEADLINE14 {
  width: 311px;
}

#HEADLINE11 {
  top: 639.5px;
  left: 15px;
}

#HEADLINE11 > .ladi-headline,
#HEADLINE14 > .ladi-headline,
#HEADLINE17 > .ladi-headline {
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  line-height: 2;
  color: rgb(255, 255, 255);
  text-align: justify;
}

#BOX3 {
  top: 881px;
  left: 80px;
}

#BOX3 > .ladi-box {
  background-image: url('/public/images/chu-re.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 0%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#HEADLINE12 {
  top: 842.5px;
  left: 260px;
}

#HEADLINE12 > .ladi-headline {
  font-family: CormorantGaramond-Bold;
  font-size: 19px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: right;
}

#HEADLINE13 {
  top: 893.5px;
  left: 152px;
}

#HEADLINE13 > .ladi-headline {
  font-family: Betalisa;
  font-size: 32px;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  text-align: right;
}

#HEADLINE14 {
  top: 1235.5px;
  left: 92px;
}

#IMAGE18,
#IMAGE18 > .ladi-image > .ladi-image-background {
  width: 142px;
  height: 142px;
}

#IMAGE18 {
  top: 314px;
  left: 350px;
}

#IMAGE24,
#IMAGE24 > .ladi-image > .ladi-image-background,
#IMAGE26,
#IMAGE26 > .ladi-image > .ladi-image-background {
  width: 151px;
  height: 151px;
}

#IMAGE24 {
  top: 1365px;
  left: -16px;
}

#IMAGE24 > .ladi-image > .ladi-image-background,
#IMAGE26 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s500x500/6322a62f2dad980013bb5005/18-20240706084907-rk017.png');
}

#IMAGE22,
#IMAGE22 > .ladi-image > .ladi-image-background {
  width: 132px;
  height: 132px;
}

#IMAGE22 {
  top: 644.5px;
  left: 326px;
}

#IMAGE22 > .ladi-image > .ladi-image-background,
#IMAGE34 > .ladi-image > .ladi-image-background,
#IMAGE35 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s450x450/6322a62f2dad980013bb5005/20-20240706085335-aaddj.png');
}

#IMAGE25,
#IMAGE25 > .ladi-image > .ladi-image-background {
  width: 237px;
  height: 237px;
}

#IMAGE25 {
  top: 1183px;
  left: -104px;
}

#IMAGE25 > .ladi-image > .ladi-image-background,
#IMAGE56 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s550x550/6322a62f2dad980013bb5005/22-20240706085639-admys.png');
}

#IMAGE25 > .ladi-image {
  opacity: 0.2;
}

#SECTION3 {
  height: 660.8px;
}

#IMAGE49 {
  top: 217px;
  left: 0px;
}

#IMAGE49 > .ladi-image {
  filter: brightness(105%);
}

#IMAGE5,
#IMAGE5 > .ladi-image > .ladi-image-background {
  width: 637px;
  height: 639px;
}

#IMAGE5 {
  top: -2px;
  left: -109px;
}

#IMAGE5 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s950x950/6322a62f2dad980013bb5005/2-20240705085525-lcmpe.png');
}

#IMAGE6,
#IMAGE6 > .ladi-image > .ladi-image-background {
  width: 252.5px;
  height: 252.5px;
}

#IMAGE6 {
  top: -39.7px;
  left: 334.5px;
}

#IMAGE6 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s600x600/6322a62f2dad980013bb5005/untitled-1-20240705083847-n_sae.png');
}

#IMAGE6 > .ladi-image {
  opacity: 0.08;
}

#IMAGE7,
#IMAGE7 > .ladi-image > .ladi-image-background {
  width: 187.5px;
  height: 187.5px;
}

#IMAGE7 {
  top: 473.3px;
  left: -58.5px;
}

#IMAGE7 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s500x500/6322a62f2dad980013bb5005/untitled-1-20240705083847-n_sae.png');
}

#IMAGE7 > .ladi-image {
  transform: perspective(1000px) rotateX(180deg) rotateY(180deg);
  opacity: 0.08;
}

#GROUP1 {
  width: 263px;
  height: 72.75px;
  top: 40.3px;
  left: 92px;
}

#HEADLINE15 {
  width: 134px;
}

#HEADLINE15 > .ladi-headline {
  font-family: Betalisa;
  font-size: 40px;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  text-align: left;
  transform: rotate(-8deg);
}

#HEADLINE16 {
  top: 43.75px;
  left: 63px;
}

#HEADLINE16 > .ladi-headline {
  font-family: CormorantGaramond-SemiBold;
  font-size: 18px;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  text-align: center;
}

#HEADLINE17 {
  width: 358px;
  top: 138.3px;
  left: 31px;
}

#GROUP26 {
  width: 200px;
  height: 42px;
  top: 556.05px;
  left: 110px;
}

#HEADLINE18 > .ladi-headline {
  font-family: CormorantGaramond-SemiBold;
  font-size: 15px;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  text-align: center;
}

#LINE1 {
  width: 36px;
  top: 24px;
  left: 82px;
}

#LINE1 > .ladi-line > .ladi-line-container {
  border-top: 2px solid rgb(255, 255, 255);
  border-right: 2px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(255, 255, 255);
  border-left: 0px !important;
}

#LINE1 > .ladi-line,
#LINE4 > .ladi-line,
#LINE5 > .ladi-line,
#LINE6 > .ladi-line,
#LINE7 > .ladi-line {
  width: 100%;
  padding: 8px 0px;
}

#SECTION4 {
  height: 1254.2px;
}

#IMAGE43 {
  top: 1217.3px;
  left: 0px;
}

#IMAGE42 {
  top: 797.3px;
  left: 0px;
}

#IMAGE41 {
  top: 377.3px;
  left: 0px;
}

#IMAGE40 {
  top: -23.8px;
  left: 0px;
}

#IMAGE27,
#IMAGE27 > .ladi-image > .ladi-image-background {
  width: 167px;
  height: 167px;
}

#IMAGE27 {
  top: 73.2px;
  left: 196px;
}

#IMAGE27 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s500x500/6322a62f2dad980013bb5005/17-20240706084654-5wcki.png');
}

#IMAGE26 {
  top: -18.2px;
  left: 163px;
}

#IMAGE8 {
  top: -23.8px;
  left: 322px;
  width: 56.562px;
  height: 139px;
}

#IMAGE8 > .ladi-image > .ladi-image-background {
  background-color: rgb(24 57 43);
}

#IMAGE9,
#IMAGE9 > .ladi-image > .ladi-image-background {
  width: 35px;
  height: 35px;
}

#IMAGE9 {
  top: 66.8px;
  left: 333px;
}

#IMAGE9 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s350x350/6322a62f2dad980013bb5005/4-20240705095911--dfax.png');
}

#LINE2 {
  height: 66px;
  top: -23.8px;
  left: 24.25px;
}

#LINE2 > .ladi-line > .ladi-line-container,
#LINE3 > .ladi-line > .ladi-line-container {
  border-top: 0px !important;
  border-right: 2px solid rgb(24 57 43);
  border-bottom: 2px solid rgb(24 57 43);
  border-left: 2px solid rgb(24 57 43);
}

#LINE2 > .ladi-line,
#LINE3 > .ladi-line {
  height: 100%;
  padding: 0px 8px;
}

#HEADLINE20 {
  width: 90px;
  top: 53.8px;
  left: 31px;
}

#HEADLINE20 > .ladi-headline,
#HEADLINE32 > .ladi-headline,
#HEADLINE33 > .ladi-headline {
  font-family: CormorantGaramond-Bold;
  font-size: 18.75px;
  line-height: 1.4;
  color: rgb(24 57 43);
  letter-spacing: 1px;
  text-align: left;
}

#LINE3 {
  height: 85px;
  top: 140.2px;
  left: 25.25px;
}

#GROUP2,
#GROUP3 {
  width: 202px;
  height: 110px;
}

#GROUP2 {
  top: 147.5px;
  left: 63px;
}

#HEADLINE21,
#HEADLINE25,
#HEADLINE26 {
  width: 125 px;
}

#HEADLINE21 > .ladi-headline,
#HEADLINE25 > .ladi-headline,
#HEADLINE26 > .ladi-headline,
#HEADLINE29 > .ladi-headline,
#HEADLINE30 > .ladi-headline,
#HEADLINE31 > .ladi-headline {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  line-height: 1.6;
  color: rgb(0, 0, 0);
  text-align: left;
}

#HEADLINE23,
#HEADLINE24,
#HEADLINE27,
#HEADLINE28 {
  width: 202px;
}

#HEADLINE23,
#HEADLINE27 {
  top: 29.6667px;
  left: 0px;
}

#HEADLINE23 > .ladi-headline,
#HEADLINE24 > .ladi-headline,
#HEADLINE27 > .ladi-headline,
#HEADLINE28 > .ladi-headline {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(0, 0, 0);
  text-align: left;
}

#HEADLINE24,
#HEADLINE28 {
  top: 59.3333px;
  left: 0px;
}

#HEADLINE25,
#HEADLINE29 {
  top: 89px;
  left: 0px;
}

#GROUP3 {
  top: 281.5px;
  left: 63px;
}

#HEADLINE30 {
  top: 415px;
  left: 63px;
}

#HEADLINE31 {
  top: 440px;
  left: 63px;
}

#HEADLINE32 {
  width: 230px;
  top: 490px;
  left: 32px;
}

#HEADLINE33 {
  width: 249px;
  top: 510px;
  left: 32px;
}

#HEADLINE34 {
  width: 276px;
  top: 570px;
  left: 73.562px;
}

#HEADLINE34 > .ladi-headline {
  font-family: Betalisa;
  font-size: 29px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#HEADLINE34.ladi-animation > .ladi-headline,
#GROUP22.ladi-animation > .ladi-group {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

#GROUP18 {
  width: 329px;
  height: 106px;
  top: 675px;
  left: 43px;
}

#HEADLINE37,
#HEADLINE38 {
  width: 126px;
}

#HEADLINE37 {
  top: 0px;
  left: 104px;
}

#HEADLINE37 > .ladi-headline {
  font-family: CormorantGaramond-SemiBold;
  font-size: 48px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#HEADLINE38 {
  top: 68px;
  left: 102px;
}

#HEADLINE38 > .ladi-headline {
  font-family: CormorantGaramond-SemiBold;
  font-size: 24px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#GROUP5,
#GROUP6 {
  width: 329px;
  height: 18px;
}

#GROUP5 {
  top: 17.6px;
  left: 0px;
}

#LINE4,
#LINE5,
#LINE6,
#LINE7 {
  width: 118px;
}

#LINE4 > .ladi-line > .ladi-line-container,
#LINE5 > .ladi-line > .ladi-line-container,
#LINE6 > .ladi-line > .ladi-line-container,
#LINE7 > .ladi-line > .ladi-line-container {
  border-top: 1px solid rgb(24 57 43);
  border-right: 1px solid rgb(24 57 43);
  border-bottom: 1px solid rgb(24 57 43);
  border-left: 0px !important;
}

#LINE5,
#LINE7 {
  top: 0px;
  left: 211px;
}

#GROUP6 {
  top: 56.6px;
  left: 0px;
}

#HEADLINE39,
#HEADLINE50,
#HEADLINE35 {
  width: 106px;
}

#HEADLINE39 {
  top: 30.6px;
  left: 6px;
}

#HEADLINE39 > .ladi-headline,
#HEADLINE42 > .ladi-headline,
#HEADLINE44 > .ladi-headline {
  font-family: CormorantGaramond-SemiBold;
  font-size: 20px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#GROUP7 {
  width: 99px;
  height: 46px;
  top: 19.6px;
  left: 221px;
}

#HEADLINE40,
#HEADLINE45,
#HEADLINE47,
#HEADLINE48,
#HEADLINE49 {
  width: 62px;
}

#HEADLINE40 > .ladi-headline,
#HEADLINE45 > .ladi-headline,
#HEADLINE47 > .ladi-headline,
#HEADLINE48 > .ladi-headline,
#HEADLINE49 > .ladi-headline {
  font-family: CormorantGaramond-Medium;
  font-size: 29px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#HEADLINE42 {
  width: 42px;
  top: 10px;
  left: 57px;
}

#HEADLINE43 {
  width: 274px;
  top: 790px;
  left: 70.5px;
}

#HEADLINE43 > .ladi-headline,
#HEADLINE50 > .ladi-headline,
#HEADLINE51 > .ladi-headline,
#HEADLINE52 > .ladi-headline {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#GROUP8 {
  width: 369px;
  height: 69px;
  top: 835px;
  left: 25.5px;
}

#HEADLINE44 {
  width: 369px;
}

#HEADLINE45 {
  top: 23px;
  left: 204.5px;
}

#GROUP19 {
  width: 106px;
  height: 136.248px;
  top: 930px;
  left: 32px;
}

#IMAGE10,
#IMAGE10 > .ladi-image > .ladi-image-background {
  width: 64.375px;
  height: 64.375px;
}

#IMAGE10 {
  top: 0px;
  left: 21.375px;
}

#IMAGE10 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s400x400/6322a62f2dad980013bb5005/gege-20240719034231-q_l2e.png');
}

#HEADLINE47 {
  top: 65.25px;
  left: 22.5625px;
}

#HEADLINE50 {
  top: 115.248px;
  left: 0px;
}

#GROUP21 {
  width: 86px;
  height: 136.56px;
  top: 930px;
  left: 168.562px;
}

#IMAGE11,
#IMAGE11 > .ladi-image > .ladi-image-background {
  width: 65px;
  height: 65px;
}

#IMAGE11 {
  top: 0px;
  left: 10.5px;
}

#IMAGE11 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s400x400/6322a62f2dad980013bb5005/gegwerew-20240719041801-m0is6.png');
}

#HEADLINE48 {
  top: 65.562px;
  left: 12px;
}

#HEADLINE51 {
  width: 86px;
  top: 115.56px;
  left: 0px;
}

#GROUP20 {
  width: 98px;
  height: 132.685px;
  top: 930px;
  left: 288px;
}

#IMAGE12,
#IMAGE12 > .ladi-image > .ladi-image-background {
  width: 57.25px;
  height: 57.25px;
}

#IMAGE12 {
  top: 0px;
  left: 17.375px;
}

#IMAGE12 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s400x400/6322a62f2dad980013bb5005/gregw-20240719041801-7znac.png');
}

#HEADLINE49 {
  top: 61.687px;
  left: 18px;
}

#HEADLINE52 {
  width: 98px;
  top: 111.685px;
  left: 0px;
}

#HEADLINE53 {
  width: 406px;
  top: 1090px;
  left: 5px;
}

#HEADLINE53 > .ladi-headline {
  font-family: CormorantGaramond-SemiBold;
  font-size: 18px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#HEADLINE55 {
  width: 306px;
  top: 1155px;
  left: 57px;
}

#HEADLINE55 > .ladi-headline,
#HEADLINE60 > .ladi-headline,
#HEADLINE98 > .ladi-headline,
#HEADLINE99 > .ladi-headline,
#HEADLINE100 > .ladi-headline,
#HEADLINE102 > .ladi-headline,
#HEADLINE62 > .ladi-headline {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#HEADLINE101 > .ladi-headline {
  font-family: Betalisa;
  font-size: 55px;
  line-height: 1;
  color: rgb(24 57 43);
  text-align: center;
}

#GROUP10 {
  width: 138px;
  height: 27px;
  top: 1210px;
  left: 148px;
}

#IMAGE13 {
  width: 21px;
  height: 21.0001px;
  top: 3px;
  left: 0px;
}

#IMAGE13 > .ladi-image > .ladi-image-background {
  width: 15px;
  height: 21px;
  background-image: url('https://w.ladicdn.com/s350x350/6322a62f2dad980013bb5005/untitled-gdg-20240719034034--fmcr.png');
}

#IMAGE13 {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#HEADLINE56 {
  width: 129px;
  top: 0px;
  left: 9px;
}

#HEADLINE56 > .ladi-headline {
  font-family: CormorantGaramond-Bold;
  font-size: 17px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#HEADLINE35 {
  top: 650px;
  left: 154.5px;
}

#HEADLINE35 > .ladi-headline {
  font-family: CormorantGaramond-SemiBold;
  font-size: 18px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#SECTION5 {
  height: 1546.2px;
}

#IMAGE46 {
  top: 1223.1px;
  left: 0px;
}

#IMAGE45 {
  top: 803.1px;
  left: 0px;
}

#IMAGE44 {
  top: 383.1px;
  left: 0px;
}

#BOX5 {
  width: 392px;
  height: 143px;
  top: 0px;
  left: 15px;
}

#BOX5 > .ladi-box {
  background-image: url('/public/images/album-1.webp');
  background-origin: content-box;
  background-position: 50% 40%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: 100%;
}

#BOX5 > .ladi-box,
#BOX6 > .ladi-box,
#BOX7 > .ladi-box,
#BOX8 > .ladi-box,
#BOX9 > .ladi-box,
#BOX10 > .ladi-box,
#BOX11 > .ladi-box,
#BOX12 > .ladi-box,
#BOX13 > .ladi-box,
#BOX14 > .ladi-box,
#BOX15 > .ladi-box {
  box-shadow: rgb(0, 0, 0) 0px 9px 20px -17px;
}

#BOX5.ladi-animation > .ladi-box {
  animation-name: fadeInUp;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

#BOX6,
#BOX8 {
  width: 130px;
  height: 130px;
}

#BOX6 {
  top: 155px;
  left: 15px;
}

#BOX6 > .ladi-box {
  background-image: url('/public/images/album-3.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 70% 30%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: 160%;
}

#BOX7 {
  width: 249.5px;
  height: 345px;
  top: 298px;
  left: 15px;
}

#BOX7 > .ladi-box {
  background-image: url('/public/images/album-8.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 100%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#BOX8 {
  top: 513px;
  left: 277px;
}

#BOX8 > .ladi-box {
  background-image: url('/public/images/album-4.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 0%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#BOX9 {
  width: 130px;
  height: 346px;
  top: 155px;
  left: 277px;
}

#BOX9 > .ladi-box {
  background-image: url('/public/images/album-5.webp');
  background-origin: content-box;
  background-position: 60% 83%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: 178%;
}

#GROUP22 {
  width: 116px;
  height: 81px;
  top: 175.3px;
  left: 151.5px;
}

#HEADLINE57,
#HEADLINE58 {
  width: 113px;
}

#HEADLINE57 > .ladi-headline,
#HEADLINE59 > .ladi-headline {
  font-family: Betalisa;
  font-size: 33.94px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#HEADLINE90 > .ladi-headline {
  font-family: Betalisa;
  font-size: 33.94px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#HEADLINE190 > .ladi-headline {
  font-family: Betalisa;
  font-size: 33.94px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#HEADLINE58 {
  top: 54px;
  left: 3px;
}

#HEADLINE58 > .ladi-headline {
  font-family: CormorantGaramond-SemiBold;
  font-size: 17px;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#BOX10,
#BOX11,
#BOX12,
#BOX13,
#BOX14,
#BOX15 {
  width: 189px;
  height: 273px;
}

#BOX10 {
  top: 656.8px;
  left: 15px;
}

#BOX10 > .ladi-box {
  background-image: url('/public/images/album-6.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 0%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#BOX11 {
  top: 656.8px;
  left: 218px;
}

#BOX11 > .ladi-box {
  background-image: url('/public/images/album-7.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 23% 0%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#BOX12 {
  top: 943.8px;
  left: 15px;
}

#BOX12 > .ladi-box {
  background-image: url('/public/images/album-2.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 0%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#BOX13 {
  top: 943.8px;
  left: 218px;
}

#BOX13 > .ladi-box {
  background-image: url('/public/images/album-9.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 0%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#BOX14 {
  top: 1231.8px;
  left: 15px;
}

#BOX14 > .ladi-box {
  background-image: url('/public/images/album-10.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 0%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#BOX15 {
  top: 1231.8px;
  left: 218px;
}

#BOX15 > .ladi-box {
  background-image: url('/public/images/album-11.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 0%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#SECTION6 {
  height: 852.2px;
}

#IMAGE48 {
  top: 516.9px;
  left: 0px;
}

#IMAGE90 {
  top: 255px;
  left: 0px;
}

#GROUP16 {
  width: 548.039px;
  height: 392.5px;
  top: 532.6px;
  left: -45.25px;
}

#GROUP90 {
  width: 548.039px;
  height: 392.5px;
  top: 300px;
  left: -45.25px;
  z-index: 1;
}

#IMAGE32,
#IMAGE32 > .ladi-image > .ladi-image-background {
  width: 234.25px;
  height: 234.25px;
}

#IMAGE32 {
  top: 117.5px;
  left: 313.789px;
}

#IMAGE32 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s550x550/6322a62f2dad980013bb5005/21-20240706085438-sxxkw.png');
}

#IMAGE32 > .ladi-image {
  transform: rotate(-12deg);
  opacity: 0.15;
}

#IMAGE29,
#IMAGE29 > .ladi-image > .ladi-image-background {
  width: 212px;
  height: 212px;
}

#IMAGE29 {
  top: 180.5px;
  left: 149.25px;
}

#IMAGE29 > .ladi-image {
  opacity: 0.37;
}

#IMAGE33,
#IMAGE33 > .ladi-image > .ladi-image-background {
  width: 200px;
  height: 200px;
}

#IMAGE33 {
  top: 0px;
  left: 317.25px;
}

#IMAGE33 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s550x550/6322a62f2dad980013bb5005/23-20240706090503-fbyoe.png');
}

#IMAGE28,
#IMAGE28 > .ladi-image > .ladi-image-background {
  width: 180px;
  height: 176px;
}

#IMAGE28 {
  top: 216.5px;
  left: 297.789px;
}

#IMAGE28 > .ladi-image {
  transform: perspective(1000px) rotate(-13deg) rotateY(180deg);
  opacity: 0.37;
}

#IMAGE31,
#IMAGE31 > .ladi-image > .ladi-image-background {
  width: 163.25px;
  height: 163.25px;
}

#IMAGE31 {
  top: 212.5px;
  left: 0px;
}

#IMAGE31 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s500x500/6322a62f2dad980013bb5005/21-20240706085438-sxxkw.png');
}

#IMAGE31 > .ladi-image {
  transform: rotate(4deg);
  opacity: 0.15;
}

#IMAGE34,
#IMAGE34 > .ladi-image > .ladi-image-background,
#IMAGE35 > .ladi-image > .ladi-image-background {
  width: 142.731px;
  height: 142.731px;
}

#IMAGE34 {
  top: 165.259px;
  left: 65.289px;
}

#IMAGE34 > .ladi-image {
  transform: perspective(1000px) rotate(-16deg) rotateY(180deg);
  opacity: 0.15;
}

#IMAGE35 {
  width: 142.731px;
  height: 99.741px;
  top: 100.259px;
  left: 335.058px;
}

#IMAGE35 > .ladi-image {
  transform: rotate(-2deg);
  opacity: 0.15;
}

#IMAGE47 {
  top: 0px;
  left: 0.539px;
}

#IMAGE97 {
  top: 0px;
  left: 0.539px;
}

#IMAGE98 {
  top: 0px;
  left: 0.539px;
}

#IMAGE14,
#IMAGE14 > .ladi-image > .ladi-image-background {
  width: 49.0002px;
  height: 49.0002px;
}

#IMAGE14 {
  top: 18px;
  left: 185.5px;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#IMAGE14 > .ladi-image > .ladi-image-background {
  background-image: url('/public/chu-hy-1.png');
}

#IMAGE91,
#IMAGE91 > .ladi-image > .ladi-image-background {
  width: 49.0002px;
  height: 49.0002px;
}

#IMAGE91 {
  top: 73px;
  left: 185.5px;
}

#IMAGE91 > .ladi-image > .ladi-image-background {
  background-image: url('/public/chu-hy-1.png');
}

#HEADLINE59 {
  width: 206px;
  top: 74px;
  left: 107px;
}

#HEADLINE59.ladi-animation > .ladi-headline {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#HEADLINE90 {
  width: 206px;
  top: 20px;
  left: 107px;
}

#HEADLINE190 {
  width: 220px;
  top: 20px;
  left: 107px;
}

#HEADLINE90.ladi-animation > .ladi-headline {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#HEADLINE190.ladi-animation > .ladi-headline {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

#HEADLINE60,
#HEADLINE98,
#HEADLINE99,
#HEADLINE100,
#HEADLINE101,
#HEADLINE102,
#HEADLINE62 {
  width: 381px;
}

#HEADLINE60 {
  top: 134.1px;
  left: 19.5px;
}
#HEADLINE98 {
  top: 134.1px;
  left: 19.5px;
}

#HEADLINE101 {
  top: 185px;
  left: 19.5px;
}

#FORM2 {
  width: 382.117px;
  height: 316px;
  top: 211.9px;
  left: 19.461px;
}

#FORM2 > .ladi-form {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: rgb(24 57 43);
}

#FORM2 .ladi-form .ladi-form-checkout-payment-item {
  --check-size: calc(14px * 1.5);
  --width-quantity: calc(14px * 2.5 + 30px);
}

#FORM2
  .ladi-form
  .ladi-form-item.ladi-form-checkbox
  .ladi-form-checkbox-item
  span[data-checked='false'],
#FORM2
  .ladi-form
  .ladi-form-item.ladi-form-checkbox
  .ladi-form-checkbox-item
  .ladi-editing,
#FORM2
  .ladi-form
  .ladi-form-item.ladi-form-checkbox
  .ladi-form-checkbox-item
  .ladi-editing::placeholder,
#FORM2 .ladi-form .ladi-survey-option .ladi-survey-option-label,
#FORM2 .ladi-form-item .ladi-form-control::placeholder,
#FORM2 .ladi-form-item select.ladi-form-control[data-selected=''],
#FORM2 .ladi-form-checkout-payment-quantity input {
  color: rgb(24 57 43);
}

#FORM2 .ladi-form-item {
  padding-left: 5px;
  padding-right: 5px;
}

#FORM2 .ladi-form-item.otp-countdown:before {
  right: 10px;
}

#FORM2 .ladi-form-item.ladi-form-checkbox {
  padding-left: 10px;
  padding-right: 10px;
}

#FORM2 .ladi-form-item-container .ladi-form-item .ladi-form-control-select {
  background-image: url('data:image/svg+xml;utf8, %3Csvg%20width%3D%2232%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C0%2032%2C0%2016%2C24%22%20style%3D%22fill%3A%20rgb(131%2C%204%2C%2013)%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E');
}

#FORM2
  .ladi-form
  [data-form-checkout-item='product']
  .ladi-form-checkout-payment-check.multiple.checked:before,
#FORM2
  .ladi-form
  [data-form-checkout-item='product']
  .ladi-form-checkout-payment-check:not(.multiple).checked:before,
#FORM2
  .ladi-form
  [data-form-checkout-item='payment']
  .ladi-form-checkout-payment-check.checked:before,
#FORM2 .ladi-form-item-container .ladi-form-quantity .button,
#FORM2
  .ladi-form
  [data-form-checkout-item='coupon_code']
  .content-discount-block
  .placeholder
  i,
#FORM2 .ladi-form-checkout-payment-quantity .up:before,
#FORM2 .ladi-form-checkout-payment-quantity .down:before,
#FORM2
  [data-form-checkout-item='payment']
  .ladi-form-item
  .ladi-form-checkout-payment-content
  div:before {
  background-color: rgb(24 57 43);
}

#FORM2 .ladi-survey-option {
  text-align: left;
}

#FORM2 .ladi-form-item-container,
#FORM2 .ladi-form-checkout-box,
#FORM2 .ladi-form-checkout-bump-offer-product,
#FORM2 .ladi-form-label-container .ladi-form-label-item {
  border-width: 1px;
  border-radius: 20px;
  border-style: solid;
  border-color: rgb(24 57 43);
}

#FORM2 .ladi-form-item-container .ladi-form-item.ladi-form-quantity {
  width: calc(100% + 1px);
}

#FORM2 .ladi-form [data-form-checkout-item='total_price'] .space,
#FORM2 .ladi-form-checkout-payment-quantity input {
  border-color: rgb(24 57 43);
}

#FORM2 .ladi-form-item-background {
  border-radius: 19px;
  background-color: rgba(255, 255, 255, 0);
}

#BUTTON2,
#GROUP14,
#GROUP99,
#GROUP199,
#GROUP200,
#GROUP201,
#GROUP202,
#BOX116,
#BOX117,
#BOX118,
#BOX119,
#BOX16 {
  width: 382.078px;
  height: 40px;
}

#BUTTON2 {
  top: 410px;
  left: 0px;
}

#BUTTON2 > .ladi-button > .ladi-button-background {
  background-color: rgba(1, 1, 1, 0);
}

#BUTTON2 > .ladi-button {
  border-width: 1px;
  border-radius: 28px;
  border-style: solid;
  border-color: rgb(24 57 43);
}

#BUTTON_TEXT2 {
  width: 380px;
  top: 15px;
  left: 0px;
  background-color: transparent; /* Hoặc background-color: #ffffff; */
}

#BUTTON_TEXT2 > .ladi-headline,
#HEADLINE161 > .ladi-headline,
#HEADLINE162 > .ladi-headline,
#HEADLINE163 > .ladi-headline,
#HEADLINE164 > .ladi-headline,
#HEADLINE61 > .ladi-headline {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: CormorantGaramond-Bold;
  font-size: 17px;
  line-height: 1.6;
  color: rgb(24 57 43);
  letter-spacing: 1px;
  text-align: center;
}

#FORM_ITEM_UPLOAD {
  width: 382.078px;
  height: 36px;
  top: 230px;
  left: 100px;
}

#FORM_ITEM2 {
  width: 382.078px;
  height: 36px;
  top: 0px;
  left: 0.039px;
}

#FORM_ITEM3 {
  width: 382.078px;
  height: 100px;
  top: 50px;
  left: 0.039px;
}

#FORM_ITEM4 {
  width: 382px;
  height: 100px;
  top: 200px;
  left: 0.039px;
}

#FORM_ITEM4 .ladi-form-checkbox-item {
  margin: 10px;
}

#FORM_ITEM4 .ladi-form-item.ladi-form-checkbox {
  padding-top: 5px;
  padding-bottom: 5px;
}

#FORM_ITEM4 .ladi-form-item.ladi-form-checkbox .ladi-form-checkbox-item input {
  width: 20px;
  height: 20px;
  accent-color: rgb(24 57 43);
}

#FORM_ITEM4 .ladi-form-item.ladi-form-checkbox .ladi-form-checkbox-item span {
  width: calc(100% - 17px);
}

#FORM_ITEM5 {
  width: 382px;
  height: 50px;
  top: 335px;
  left: 0.039px;
  display: flex;
  flex-direction: row; /* Đảm bảo xếp theo chiều ngang */
  align-items: center;
  justify-content: space-between; /* Khoảng cách giữa các nút */
}

#FORM_ITEM5 .ladi-form-checkbox-box-item {
  display: flex; /* Chuyển sang flex để các nút nằm ngang */
  flex-direction: row; /* Xếp ngang các phần tử con */
  gap: 10px; /* Khoảng cách giữa các radio button */
  margin: 0; /* Xóa margin thừa để căn chỉnh đều */
  display: flex; /* Căn chỉnh nhãn và nút theo hàng ngang */
  align-items: center;
  gap: 30px; /* Khoảng cách giữa nút và nhãn */
}

#FORM_ITEM5 .ladi-form-item.ladi-form-checkbox .ladi-form-checkbox-item input {
  width: 20px;
  height: 20px;
  accent-color: rgb(24 57 43);
}

#GROUP14 {
  top: 675px;
  left: 19.5px;
}

#GROUP99 {
  top: 300px;
  left: 19.5px;
  z-index: 10;
}

#GROUP199 {
  top: 225px;
  left: 19.5px;
  z-index: 10;
}

#GROUP200 {
  top: 280px;
  left: 19.5px;
  z-index: 10;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

#GROUP201 {
  top: 335px;
  left: 19.5px;
  z-index: 10;
}

#GROUP202 {
  top: 410px;
  left: 19.5px;
  z-index: 10;
}

#BOX16 > .ladi-box {
  border-width: 1px;
  border-radius: 24px;
  border-style: solid;
  border-color: rgb(24 57 43);
}

#BOX116 > .ladi-box {
  border-width: 1px;
  border-radius: 24px;
  border-style: solid;
  border-color: rgb(24 57 43);
}

#BOX117 > .ladi-box {
  border-width: 1px;
  border-radius: 24px;
  border-style: solid;
  border-color: rgb(24 57 43);
}

#BOX118 > .ladi-box {
  border-width: 1px;
  border-radius: 24px;
  border-style: solid;
  border-color: rgb(24 57 43);
}

#BOX119 > .ladi-box {
  border-width: 1px;
  border-radius: 24px;
  border-style: solid;
  border-color: rgb(24 57 43);
}

#BOX16 > .ladi-box,
#BOX116 > .ladi-box,
#BOX117 > .ladi-box,
#BOX118 > .ladi-box,
#BOX119 > .ladi-box,
#BOX17 > .ladi-box {
  background-color: rgba(242, 243, 244, 0);
}

#HEADLINE61 {
  top: 7px;
  left: 91.039px;
}

#HEADLINE161 {
  top: 7px;
  left: 91.039px;
}

#HEADLINE162 {
  top: 7px;
  left: 91.039px;
}

#HEADLINE163 {
  top: 7px;
  left: 91.039px;
}

#HEADLINE164 {
  top: 7px;
  left: 50px;
}

#HEADLINE62 {
  top: 740px;
  left: 20.039px;
}

#HEADLINE99 {
  top: 400px;
  left: 20.039px;
}

#HEADLINE100 {
  top: 475px;
  left: 20.039px;
}

#HEADLINE102 {
  top: 410px;
  left: 20.039px;
}

#GROUP15 {
  width: 381.461px;
  height: 127.1px;
  top: 792px;
  left: 20.039px;
}

#BOX17 {
  width: 381.461px;
  height: 82px;
  top: 45.1px;
  left: 0px;
}

#BOX17 > .ladi-box {
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
  border-color: rgb(24 57 43);
}

#HEADLINE63 {
  width: 70px;
  top: 0px;
  left: 18.1915px;
}

#HEADLINE63 > .ladi-headline {
  font-family: Betalisa;
  font-size: 43px;
  line-height: 1.6;
  color: rgb(24 57 43);
  letter-spacing: 1px;
  text-align: center;
}

#COUNTDOWN1 {
  width: 320px;
  height: 57px;
  top: 49.6px;
  left: 30.1915px;
}

#COUNTDOWN1 > .ladi-countdown {
  font-family: CormorantGaramond-Bold;
  font-size: 44px;
  font-weight: bold;
  color: rgb(24 57 43);
  text-align: center;
}

#COUNTDOWN1 > .ladi-countdown > .ladi-element {
  width: calc(25% - 7.5px);
  height: 100%;
  margin-right: 10px;
}

#HEADLINE64,
#HEADLINE65,
#HEADLINE66 {
  width: 41px;
}

#HEADLINE64 {
  top: 60.1px;
  left: 88.1915px;
}

#HEADLINE64 > .ladi-headline,
#HEADLINE65 > .ladi-headline,
#HEADLINE66 > .ladi-headline {
  font-family: CormorantGaramond-Bold;
  font-size: 29.87px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(24 57 43);
  text-align: center;
}

#HEADLINE65 {
  top: 60.1px;
  left: 169.691px;
}

#HEADLINE66 {
  top: 60.1px;
  left: 253.691px;
}

#SECTION7 {
  height: 390.2px;
}

#IMAGE16 {
  top: 0px;
  left: 0px;
}

#IMAGE16 .ladi-image{
  height: 235px;
}

#IMAGE16 > .ladi-image > .ladi-image-background {
  background-image: url('/public/images/footer.webp');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 55%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#HEADLINE67 {
  width: 146px;
  top: 170px;
  left: 142.539px;
}

#HEADLINE67 > .ladi-headline {
  font-family: Betalisa;
  font-size: 33.65px;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
  text-align: center;
}

#HEADLINE72 {
  width: 420px;
  top: 240px;
  left: 5px;
}

#HEADLINE72 > .ladi-headline {
  font-family: NovecentoSans-Normal;
  font-size: 13px;
  line-height: 1.6;
  color: rgb(0, 0, 0);
  letter-spacing: 4px;
  text-align: center;
  opacity: 0.3;
}

#SECTION_POPUP {
  height: 0px;
}

#POPUP1 {
  width: 402px;
  height: 400px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: block !important;
  overflow: hidden auto !important;
  max-height: 80vh;
}

#POPUP1 > .ladi-popup > .ladi-popup-background {
  background-color: rgb(255, 255, 255);
}

#GROUP25 {
  width: 409px;
  height: 406px;
  top: -6px;
  left: 1px;
}

#IMAGE50,
#IMAGE50 > .ladi-image > .ladi-image-background {
  width: 400px;
  height: 400px;
}

#IMAGE50 {
  top: 6px;
  left: 0px;
}

#IMAGE51 {
  width: 124px;
  height: 147px;
  top: 148px;
  left: 285px;
}

#IMAGE51 > .ladi-image > .ladi-image-background {
  width: 147px;
  height: 147px;
}

#IMAGE55,
#IMAGE55 > .ladi-image > .ladi-image-background {
  width: 82px;
  height: 82px;
}

#IMAGE55 > .ladi-image > .ladi-image-background {
  background-image: url('https://w.ladicdn.com/s400x400/6322a62f2dad980013bb5005/24-20240706102431-sqnrz.png');
}

#IMAGE55 > .ladi-image {
  transform: perspective(1000px) rotateX(180deg);
  opacity: 0.15;
}

#BOX18 {
  width: 283px;
  height: 207px;
  top: 41.5px;
  left: 31px;
}

#BOX18 > .ladi-box {
  background-image: url('https://w.ladicdn.com/s600x550/6322a62f2dad980013bb5005/5-20240706041203-z4mre.jpg');
  background-size: cover;
  background-origin: content-box;
  background-position: 50% 0%;
  background-repeat: repeat;
  background-attachment: scroll;
}

#IMAGE56 {
  width: 185.795px;
  height: 209.628px;
  top: 6px;
  left: 214.205px;
}

#IMAGE56 > .ladi-image > .ladi-image-background {
  width: 211.795px;
  height: 211.795px;
  top: -1.08335px;
  left: -80.1681px;
}

#IMAGE56 > .ladi-image {
  transform: perspective(1000px) rotateY(180deg);
  opacity: 0.2;
}
#IMAGE59,
#IMAGE57,
#IMAGE57 > .ladi-image > .ladi-image-background,
#IMAGE59 > .ladi-image > .ladi-image-background {
  width: 108.272px;
  height: 107.412px;
}

#IMAGE57 {
  top: 70px;
  left: 29px;
}

#IMAGE57 > .ladi-image > .ladi-image-background {
  background-image: url('/public/images/qr-codau.png');
}

#IMAGE57.ladi-animation > .ladi-image {
  animation-name: fadeInLeft;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

#IMAGE59 {
  top: 260px;
  left: 250px;
}

#IMAGE59 > .ladi-image > .ladi-image-background {
  background-image: url('/public/images/qr-chure.png');
}

#IMAGE59.ladi-animation > .ladi-image {
  animation-name: fadeInRight;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

#GROUP24 {
  width: 226px;
  height: 116.059px;
  top: 63px;
  left: 154.5px;
}

#GROUP24.ladi-animation > .ladi-group {
  animation-name: fadeInRight;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

#GROUP27 {
  width: 226px;
  height: 116.059px;
  top: 250px;
  left: 65px;
}

#GROUP27.ladi-animation > .ladi-group {
  animation-name: fadeInLeft;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

#HEADLINE68 {
  width: 83px;
}

#HEADLINE68 > .ladi-headline {
  font-family: CormorantGaramond-Bold;
  font-size: 17px;
  line-height: 1.6;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  text-align: left;
}

#HEADLINE68.ladi-animation > .ladi-headline,
#HEADLINE69.ladi-animation > .ladi-headline,
#HEADLINE70.ladi-animation > .ladi-headline,
#HEADLINE71.ladi-animation > .ladi-headline {
  animation-name: fadeInRight;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

#HEADLINE69 {
  width: 226px;
  top: 31.706px;
  left: 0px;
}

#HEADLINE69 > .ladi-headline {
  font-family: Betalisa;
  font-size: 23px;
  line-height: 1.6;
  color: rgb(1, 1, 1);
  text-align: left;
}

#HEADLINE70,
#HEADLINE71 {
  width: 210px;
}

#HEADLINE70 {
  top: 71.706px;
  left: 0px;
}

#HEADLINE70 > .ladi-headline {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  line-height: 1.6;
  color: rgb(1, 1, 1);
  letter-spacing: 1px;
  text-align: left;
}

#HEADLINE71 {
  top: 95.059px;
  left: 0px;
}

#HEADLINE71 > .ladi-headline {
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  line-height: 1.6;
  color: rgb(1, 1, 1);
  letter-spacing: 1px;
  text-align: left;
}

#MAP {
  width: 365px;
  height: 320px;
  top: 1250px;
  left: 27px;
}

#MAP > .ladi-map {
  width: 100%;
  height: 100%;
}

#MAP > .ladi-map {
  animation-name: fadeInUp;
  animation-delay: 0s;
  animation-duration: 3s;
  animation-iteration-count: 1;
}

.frame-html-box iframe {
  width: 100%;
  height: 100%;
}
