/* .splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://w.ladicdn.com/s750x750/6322a62f2dad980013bb5005/bd95284c466fe631bf7e-20240706091144-0xksq.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-out;
  z-index: 9999;
} */

.splash-screen.fade-out {
  opacity: 0;
  animation-name: fadeOut;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

.splash-screen p {
  font-size: 1.5em;
  color: #333;
}
