.child-wish-container {
  max-height: 320px;
  overflow-y: scroll;
  padding: 15px 15px;
  scrollbar-width: thin;
  scrollbar-color: #18392b #f0f0f0;
}
.background-container {
  background-image: url('https://w.ladicdn.com/s750x750/6322a62f2dad980013bb5005/bd95284c466fe631bf7e-20240706091144-0xksq.jpg');
  filter: brightness(104%);
}

.child-wish-container::-webkit-scrollbar {
  width: 8px;
}

.child-wish-container::-webkit-scrollbar-track {
  background: #18392b;
}

.child-wish-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #18392b;
}

.wish-title {
  font-family: Betalisa;
  font-size: 33.94px;
  line-height: 1.6;
  color: #18392b;
  text-align: center;
}

.wish-title .ladi-headline {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.wish-item {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
}

.wish-item:hover {
  background-color: #f0f8ff;
}

.wish-item strong {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #18392b;
  text-align: center;
  font-weight: 600;
}

.wish-message {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  line-height: 1.6;
  color: #18392b;
}

.loading-message {
  text-align: center;
  font-style: italic;
  color: #888;
  height: 320px;
}
