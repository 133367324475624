/* Common styles for the container */
.container {
  max-width: 800px;
  margin: 20px auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Specific styles for header */
.header-title {
  color: #4caf50;
  text-align: center;
  margin-bottom: 20px;
}

/* Specific styles for input */
.input-field {
  width: calc(100% - 120px);
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Specific styles for button */
.button {
  padding: 10px 20px;
  border: none;
  background-color: #4caf50;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #45a049;
}

/* Specific styles for 'copy' button */
.button.copy {
  background-color: #007bff;
}

.button.copy:hover {
  background-color: #0056b3;
}

/* Specific styles for delete button */
.button.delete {
  background-color: #e53935;
}

.button.delete:hover {
  background-color: #d32f2f;
}

/* Specific styles for cancel button */
.button.cancel {
  background-color: #757575;
}

.button.cancel:hover {
  background-color: #616161;
}

/* List styles */
ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.edit-buttons {
  display: flex;
  gap: 10px;
}
