.rotate-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1000;
  font-family: 'Arial', sans-serif; /* Added font-family */
}

.rotate-screen-overlay img {
  max-width: 50%; /* Adjusted to make the image smaller */
  height: auto;
}

.rotate-screen-overlay p {
  margin: 20px; /* Added margin to all sides */
  font-size: 1.5em;
  text-align: center;
  line-height: 1.5;
  font-family: 'Arial', sans-serif; /* Added font-family */
}
