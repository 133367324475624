/* .App {
  font-family: 'Open Sans', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  direction: ltr;
}

@font-face {
  font-family: 'Betalisa';
  src: url('https://w.ladicdn.com/6322a62f2dad980013bb5005/svn-betalisa-20240504033342-r1kle.otf');
}

@font-face {
  font-family: 'CormorantGaramond-Medium';
  src: url('https://w.ladicdn.com/6322a62f2dad980013bb5005/cormorantgaramond-medium-20240504033609-ajpqh.ttf')
    format('truetype');
}

@font-face {
  font-family: 'CormorantGaramond-Bold';
  src: url('https://w.ladicdn.com/6322a62f2dad980013bb5005/cormorantgaramond-bold-20240504042649-c3egv.ttf')
    format('truetype');
}

@font-face {
  font-family: 'CormorantGaramond-SemiBold';
  src: url('https://w.ladicdn.com/6322a62f2dad980013bb5005/cormorantgaramond-semibold-20240504034455-wikph.ttf')
    format('truetype');
}

@font-face {
  font-family: 'NovecentoSans-Normal';
  src: url('https://w.ladicdn.com/6322a62f2dad980013bb5005/9slide03-icielnovecentosans-normal-20240318082911-dgg1p.ttf')
    format('truetype');
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
button,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
select,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
textarea,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

a {
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ladi-loading {
  z-index: 900000000000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.ladi-loading .loading {
  width: 80px;
  height: 80px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  overflow: hidden;
  position: absolute;
}

.ladi-loading .loading div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: ladi-loading 1.2s linear infinite;
}

.ladi-loading .loading div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.ladi-loading .loading div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.ladi-loading .loading div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.ladi-loading .loading div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.ladi-loading .loading div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.ladi-loading .loading div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.ladi-loading .loading div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.ladi-loading .loading div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.ladi-loading .loading div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.ladi-loading .loading div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.ladi-loading .loading div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.ladi-loading .loading div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes ladi-loading {
  0%,
  100%,
  20%,
  80% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

.ladipage-message {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000000000;
  background: rgba(0, 0, 0, 0.3);
}

.ladipage-message .ladipage-message-box {
  width: 400px;
  max-width: calc(100% - 50px);
  height: 160px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #fff;
  position: fixed;
  top: calc(50% - 155px);
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 10px;
}

.ladipage-message .ladipage-message-box span {
  display: block;
  background-color: rgba(6, 21, 40, 0.05);
  color: #000;
  padding: 12px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ladipage-message .ladipage-message-box .ladipage-message-text {
  display: -webkit-box;
  font-size: 14px;
  padding: 0 20px;
  margin-top: 16px;
  line-height: 20px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.ladipage-message .ladipage-message-box .ladipage-message-close {
  display: block;
  position: absolute;
  right: 15px;
  bottom: 10px;
  margin: 0 auto;
  padding: 10px 0;
  border: none;
  width: 80px;
  text-transform: uppercase;
  text-align: center;
  color: #000;
  background-color: #e6e6e6;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  cursor: pointer;
  outline: 0;
}

.lightbox-screen {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 9000000080;
  background: rgba(0, 0, 0, 0.5);
}

.lightbox-screen .lightbox-close {
  position: absolute;
  z-index: 9000000090;
  cursor: pointer;
}

.lightbox-screen .lightbox-hidden {
  display: none;
}

.lightbox-screen .lightbox-close {
  width: 16px;
  height: 16px;
  margin: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('data:image/svg+xml;utf8, %3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23fff%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M23.4144%202.00015L2.00015%2023.4144L0.585938%2022.0002L22.0002%200.585938L23.4144%202.00015Z%22%3E%3C%2Fpath%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2.00015%200.585938L23.4144%2022.0002L22.0002%2023.4144L0.585938%202.00015L2.00015%200.585938Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
}

body {
  font-size: 12px;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -o-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  background-color: #fff;
}

.overflow-hidden {
  overflow: hidden;
}

.ladi-transition {
  transition: all 150ms linear 0s;
}

.z-index-1 {
  z-index: 1;
}

.opacity-0 {
  opacity: 0;
}

.height-0 {
  height: 0 !important;
}

.pointer-events-none {
  pointer-events: none;
}

.transition-parent-collapse-height {
  transition: height 150ms linear 0s;
}

.transition-parent-collapse-top {
  transition: top 150ms linear 0s;
}

.transition-readmore {
  transition: height 350ms linear 0s;
}

.transition-collapse {
  transition: height 150ms linear 0s;
}

body.grab {
  cursor: grab;
}

.ladi-wraper {
  width: 420px;
  min-height: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.ladi-container {
  position: relative;
  margin: 0 auto;
  height: 100%;
}

.ladi-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.ladi-element {
  position: absolute;
}

@media (hover: hover) {
  .ladi-check-hover {
    opacity: 0;
  }
}

.ladi-section {
  margin: 0 auto;
  position: relative;
  scroll-snap-align: center;
}

.ladi-section[data-tab-id] {
  display: none;
}

.ladi-section.selected[data-tab-id] {
  display: block;
}

.ladi-section .ladi-section-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
}

.ladi-box {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#SECTION_POPUP .ladi-container {
  z-index: 90000070;
}

#SECTION_POPUP .ladi-container > .ladi-element {
  z-index: 90000070;
  position: fixed;
  display: none;
}

#SECTION_POPUP .ladi-container > .ladi-element[data-fixed-close='true'] {
  position: relative !important;
}

#SECTION_POPUP .ladi-container > .ladi-element.hide-visibility {
  display: block !important;
  visibility: hidden !important;
}

#SECTION_POPUP .popup-close {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 9000000080;
  cursor: pointer;
  width: 26px;
  height: 26px;
  margin: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('data:image/svg+xml;utf8, %3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23000%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M23.4144%202.00015L2.00015%2023.4144L0.585938%2022.0002L22.0002%200.585938L23.4144%202.00015Z%22%3E%3C%2Fpath%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2.00015%200.585938L23.4144%2022.0002L22.0002%2023.4144L0.585938%202.00015L2.00015%200.585938Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
}

.ladi-popup {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ladi-popup .ladi-popup-background {
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.ladi-countdown {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.ladi-countdown .ladi-countdown-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  display: table;
  pointer-events: none;
}

.ladi-countdown .ladi-countdown-text {
  position: absolute;
  width: 100%;
  height: 100%;
  text-decoration: inherit;
  display: table;
  pointer-events: none;
}

.ladi-countdown .ladi-countdown-text span {
  display: table-cell;
  vertical-align: middle;
}

.ladi-countdown > .ladi-element {
  text-decoration: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  position: relative;
  display: inline-block;
}

.ladi-countdown > .ladi-element:last-child {
  margin-right: 0px !important;
}

.ladi-button {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ladi-button:active {
  transform: translateY(2px);
  transition: transform 0.2s linear;
}

.ladi-button .ladi-button-background {
  height: 100%;
  width: 100%;
  pointer-events: none;
  transition: inherit;
}

.ladi-button > .ladi-button-headline,
.ladi-button > .ladi-button-shape {
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  display: table;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.ladi-button > .ladi-button-shape .ladi-shape {
  margin: auto;
  top: 0;
  bottom: 0;
}

.ladi-button > .ladi-button-headline .ladi-headline {
  display: flex;
  justify-content: center;
}

.ladi-form {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ladi-form > .ladi-element {
  text-transform: inherit;
  text-decoration: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
}

.ladi-form .ladi-button > .ladi-button-headline {
  color: initial;
  font-size: initial;
  font-weight: initial;
  text-transform: initial;
  text-decoration: initial;
  font-style: initial;
  text-align: initial;
  letter-spacing: initial;
  line-height: initial;
}

.ladi-form > .ladi-element .ladi-form-item-container {
  text-transform: inherit;
  text-decoration: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
}

.ladi-form > [data-quantity='true'] .ladi-form-item-container {
  overflow: hidden;
}

.ladi-form > .ladi-element .ladi-form-item-container .ladi-form-item {
  text-transform: inherit;
  text-decoration: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  color: inherit;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item-background {
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-control-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-size: 9px 6px !important;
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  padding-right: 24px;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-control-select-2 {
  width: calc(100% / 2 - 5px);
  max-width: calc(100% / 2 - 5px);
  min-width: calc(100% / 2 - 5px);
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-control-select-2:nth-child(3) {
  margin-left: 7.5px;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-control-select-3 {
  width: calc(100% / 3 - 5px);
  max-width: calc(100% / 3 - 5px);
  min-width: calc(100% / 3 - 5px);
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-control-select-3:nth-child(3) {
  margin-left: 7.5px;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-control-select-3:nth-child(4) {
  margin-left: 7.5px;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-control-select
  option {
  color: initial;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-control:not(.ladi-form-control-select) {
  text-transform: inherit;
  text-decoration: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-control-select {
  text-transform: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-control-select:not([data-selected='']) {
  text-decoration: inherit;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-checkbox-item {
  text-transform: inherit;
  text-decoration: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  vertical-align: middle;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-checkbox-box-item {
  display: inline-block;
  width: fit-content;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-checkbox-item
  span {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-checkbox-item
  span[data-checked='true'] {
  text-transform: inherit;
  text-decoration: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
}

.ladi-form
  > .ladi-element
  .ladi-form-item-container
  .ladi-form-item
  .ladi-form-checkbox-item
  span[data-checked='false'] {
  text-transform: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
}

.ladi-form .ladi-form-item-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ladi-form .ladi-form-item-title-value {
  font-weight: bold;
  word-break: break-word;
}

.ladi-form .ladi-form-label-container {
  position: relative;
  width: 100%;
}

.ladi-form .ladi-form-control-file {
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) center;
}

.ladi-form .ladi-form-label-container .ladi-form-label-item {
  display: inline-block;
  cursor: pointer;
  position: relative;
  border-radius: 0px !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.ladi-form .ladi-form-label-container .ladi-form-label-item.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.ladi-form .ladi-form-label-container .ladi-form-label-item.no-value {
  display: none !important;
}

.ladi-form .ladi-form-label-container .ladi-form-label-item.text.disabled {
  opacity: 0.35;
}

.ladi-form .ladi-form-label-container .ladi-form-label-item.image.disabled {
  opacity: 0.2;
}

.ladi-form .ladi-form-label-container .ladi-form-label-item.color.disabled {
  opacity: 0.15;
}

.ladi-form .ladi-form-label-container .ladi-form-label-item.selected:before {
  content: '';
  width: 0;
  height: 0;
  bottom: -1px;
  right: -1px;
  position: absolute;
  border-width: 0 0 15px 15px;
  border-color: transparent;
  border-style: solid;
}

.ladi-form .ladi-form-label-container .ladi-form-label-item.selected:after {
  content: '';
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 12 12' viewBox='0 0 12 12' x='0' fill='%23fff' y='0'%3E%3Cg%3E%3Cpath d='m5.2 10.9c-.2 0-.5-.1-.7-.2l-4.2-3.7c-.4-.4-.5-1-.1-1.4s1-.5 1.4-.1l3.4 3 5.1-7c .3-.4 1-.5 1.4-.2s.5 1 .2 1.4l-5.7 7.9c-.2.2-.4.4-.7.4 0-.1 0-.1-.1-.1z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: bottom right;
  width: 7px;
  height: 7px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.ladi-form .ladi-form-item {
  width: 100%;
  height: 100%;
  position: absolute;
}

.ladi-form .ladi-form-item-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.ladi-form .ladi-form-item.ladi-form-checkbox {
  height: auto;
}

.ladi-form .ladi-form-item .ladi-form-control {
  background-color: transparent;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  padding: 0 5px;
  color: inherit;
  font-size: inherit;
  border: none;
}

.ladi-form .ladi-form-item.ladi-form-checkbox {
  padding: 0 5px;
}

.ladi-form
  .ladi-form-item.ladi-form-checkbox.ladi-form-checkbox-vertical
  .ladi-form-checkbox-item {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: flex;
  align-items: center;
  border: none;
}

.ladi-form
  .ladi-form-item.ladi-form-checkbox.ladi-form-checkbox-horizontal
  .ladi-form-checkbox-item {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 10px !important;
  display: inline-flex;
  align-items: center;
  border: none;
  position: relative;
}

.ladi-form .ladi-form-item.ladi-form-checkbox .ladi-form-checkbox-item input {
  margin-right: 5px;
  display: block;
}

.ladi-form .ladi-form-item.ladi-form-checkbox .ladi-form-checkbox-item span {
  cursor: default;
  word-break: break-word;
}

.ladi-form .ladi-form-item textarea.ladi-form-control {
  resize: none;
  padding: 5px;
}

.ladi-form .ladi-button {
  cursor: pointer;
}

.ladi-form .ladi-button .ladi-headline {
  cursor: pointer;
  user-select: none;
}

.ladi-form .ladi-element .ladi-form-otp::-webkit-outer-spin-button,
.ladi-form .ladi-element .ladi-form-otp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ladi-form .ladi-element .ladi-form-item .button-get-code {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  line-height: initial;
  padding: 5px 10px;
  height: max-content;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.ladi-form .ladi-element .ladi-form-item .button-get-code.hide-visibility {
  display: block !important;
  visibility: hidden !important;
}

.ladi-form .ladi-form-item.otp-resend .button-get-code {
  display: block;
}

.ladi-form .ladi-form-item.otp-countdown:before {
  content: attr(data-countdown-time) 's';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: max-content;
  line-height: initial;
}

.ladi-form [data-variant='true'] select option[disabled] {
  background: #fff;
  color: #b8b8b8 !important;
}

.ladi-google-recaptcha-checkbox {
  position: absolute;
  display: inline-block;
  transform: translateY(-100%);
  margin-top: -5px;
  z-index: 90000010;
}

.ladi-element[data-form-checkout-item] {
  padding: 0;
}

.ladi-form .ladi-form-checkout-title {
  margin-bottom: 8px;
}

.ladi-form .ladi-element[data-form-checkout-item] .ladi-form-item-container,
.ladi-form .ladi-element[data-form-checkout-item] .ladi-form-item {
  height: auto !important;
  position: unset !important;
}

.ladi-form .ladi-form-checkout-box {
  gap: 12px;
  display: flex;
  flex-flow: column;
  margin: 8px 12px;
  margin-top: 14px !important;
  padding-bottom: 14px;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-radius: 0 !important;
}

.ladi-form .ladi-form-checkout-box:last-child {
  border: none !important;
  padding-bottom: 4px;
}

.ladi-form .ladi-form-checkout-box:first-child {
  margin-top: 0;
}

.ladi-form .ladi-form-checkout-payment-item {
  display: flex;
  flex-flow: row;
  gap: 12px;
  align-items: center;
  --check-size: 18px;
  --width-quantity: 60px;
}

.ladi-form .ladi-form-checkout-payment-check {
  --border-size: 1px;
  width: var(--check-size);
  height: var(--check-size);
  flex: 0 0 var(--check-size);
  border: var(--border-size) solid;
  border-radius: 100%;
  display: block;
  position: relative;
}

.ladi-form .ladi-form-checkout-payment-check.checked:before {
  content: '';
  width: calc(var(--check-size) / 2);
  height: calc(var(--check-size) / 2);
  border-radius: inherit;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(
    calc(50% - var(--border-size)),
    calc(50% - var(--border-size))
  );
}

.ladi-form .ladi-form-checkout-payment-check.multiple {
  border-radius: 4px;
}

.ladi-form .ladi-form-checkout-payment-check.multiple.checked:before {
  content: '';
  --url: url('data:image/svg+xml,%0A%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M18.71%207.20998C18.617%207.11625%2018.5064%207.04186%2018.3846%206.99109C18.2627%206.94032%2018.132%206.91418%2018%206.91418C17.868%206.91418%2017.7373%206.94032%2017.6154%206.99109C17.4936%207.04186%2017.383%207.11625%2017.29%207.20998L9.84%2014.67L6.71%2011.53C6.61348%2011.4367%206.49954%2011.3634%206.37468%2011.3142C6.24983%2011.265%206.1165%2011.2409%205.98232%2011.2432C5.84814%2011.2455%205.71573%2011.2743%205.59265%2011.3278C5.46957%2011.3812%205.35824%2011.4585%205.265%2011.555C5.17176%2011.6515%205.09845%2011.7654%205.04924%2011.8903C5.00004%2012.0152%204.97591%2012.1485%204.97823%2012.2827C4.98055%2012.4168%205.00928%2012.5492%205.06277%2012.6723C5.11627%2012.7954%205.19348%2012.9067%205.29%2013L9.13%2016.84C9.22296%2016.9337%209.33356%2017.0081%209.45542%2017.0589C9.57728%2017.1096%209.70799%2017.1358%209.84%2017.1358C9.97201%2017.1358%2010.1027%2017.1096%2010.2246%2017.0589C10.3464%2017.0081%2010.457%2016.9337%2010.55%2016.84L18.71%208.67998C18.8115%208.58634%2018.8925%208.47269%2018.9479%208.34619C19.0033%208.21969%2019.0319%208.08308%2019.0319%207.94498C19.0319%207.80688%2019.0033%207.67028%2018.9479%207.54378C18.8925%207.41728%2018.8115%207.30363%2018.71%207.20998Z%22%20fill%3D%22%231852FA%22%2F%3E%0A%3C%2Fsvg%3E%0A');
  -webkit-mask-image: var(--url);
  mask-image: var(--url);
  width: var(--check-size);
  height: var(--check-size);
  -webkit-mask-size: var(--check-size);
  mask-size: var(--check-size);
  pointer-events: none;
  background-color: #fff;
  position: absolute;
  top: -1px;
  left: -1px;
  transform: none;
}

.ladi-form .ladi-form-checkout-payment-content,
.ladi-form .ladi-form-checkout-product-content {
  display: flex;
  flex-flow: row;
  --gap: 10px;
  gap: var(--gap);
  align-items: center;
  width: calc(
    100% - var(--width-quantity) - var(--check-size) - var(--gap) * 2
  );
}

.ladi-form .ladi-form-checkout-payment-content img,
.ladi-form .ladi-form-checkout-product-content img {
  width: 36px;
  max-height: 36px;
  border-radius: 4px;
}

.ladi-form .ladi-form-checkout-payment-content div,
.ladi-form .ladi-form-checkout-product-content div {
  display: flex;
  flex-flow: column;
}

.ladi-form .ladi-form-checkout-payment-content div > span {
  font-size: inherit;
}

.ladi-form .ladi-form-checkout-product-content div > span {
  font-size: inherit;
}

.ladi-form .ladi-form-checkout-payment-content div > span.small {
  font-size: 80%;
  opacity: 0.8;
}

.ladi-form .ladi-form-checkout-product-content div > span.small {
  font-size: 80%;
  opacity: 0.8;
}

.ladi-form .ladi-form-checkout-payment-content div .price.price-compare {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row !important;
}

.ladi-form .ladi-form-checkout-product-content div .price.price-compare {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row !important;
}

.ladi-form .ladi-form-checkout-payment-quantity,
.ladi-form .ladi-form-checkout-product-quantity {
  position: relative;
  --icon-size: calc(var(--check-size) * 0.8);
}

.ladi-form .ladi-form-checkout-payment-quantity input,
.ladi-form .ladi-form-checkout-product-quantity input {
  padding: 4px calc(var(--check-size) + 10px) 4px 8px;
  border-radius: 8px;
  position: relative;
  width: var(--width-quantity);
  min-height: 34px;
  border: 1px solid;
  background-color: transparent;
  top: 0;
  left: 0;
  display: block;
}

.ladi-form
  .ladi-form-checkout-payment-quantity
  input::-webkit-outer-spin-button,
.ladi-form
  .ladi-form-checkout-payment-quantity
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ladi-form .ladi-form-checkout-payment-quantity .up {
  width: var(--icon-size);
  height: var(--icon-size);
  top: -1px;
  right: 5px;
  cursor: pointer;
  display: block;
  position: absolute;
}

.ladi-form .ladi-form-checkout-payment-quantity .up:before {
  content: '';
  --url: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3.14645%206.14645C3.34171%205.95118%203.65829%205.95118%203.85355%206.14645L8%2010.2929L12.1464%206.14645C12.3417%205.95118%2012.6583%205.95118%2012.8536%206.14645C13.0488%206.34171%2013.0488%206.65829%2012.8536%206.85355L8.35355%2011.3536C8.15829%2011.5488%207.84171%2011.5488%207.64645%2011.3536L3.14645%206.85355C2.95118%206.65829%202.95118%206.34171%203.14645%206.14645Z%22%20fill%3D%22black%22%20transform%3D%22rotate(180%208%208)%22%2F%3E%3C%2Fsvg%3E');
  -webkit-mask-image: var(--url);
  mask-image: var(--url);
  display: block;
  position: absolute;
  width: var(--icon-size);
  height: var(--icon-size);
  pointer-events: none;
  top: 4px;
  left: 2px;
  -webkit-mask-size: var(--icon-size);
  mask-size: var(--icon-size);
}

.ladi-form .ladi-form-checkout-payment-quantity .down {
  width: var(--icon-size);
  height: var(--icon-size);
  right: 5px;
  cursor: pointer;
  display: block;
  position: absolute;
  bottom: 3px;
}

.ladi-form .ladi-form-checkout-payment-quantity .down:before {
  content: '';
  --url: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3.14645%206.14645C3.34171%205.95118%203.65829%205.95118%203.85355%206.14645L8%2010.2929L12.1464%206.14645C12.3417%205.95118%2012.6583%205.95118%2012.8536%206.14645C13.0488%206.34171%2013.0488%206.65829%2012.8536%206.85355L8.35355%2011.3536C8.15829%2011.5488%207.84171%2011.5488%207.64645%2011.3536L3.14645%206.85355C2.95118%206.65829%202.95118%206.34171%203.14645%206.14645Z%22%20fill%3D%22black%22%2F%3E%0A%3C%2Fsvg%3E');
  -webkit-mask-image: var(--url);
  mask-image: var(--url);
  display: block;
  position: absolute;
  width: var(--icon-size);
  height: var(--icon-size);
  pointer-events: none;
  left: 2px;
  -webkit-mask-size: var(--icon-size);
  mask-size: var(--icon-size);
}

.ladi-form [data-form-checkout-item='payment'] .ladi-form-item {
  display: table;
}

.ladi-form
  [data-form-checkout-item='payment']
  .ladi-form-item
  .ladi-form-checkout-payment-content {
  width: calc(100% - 18px);
}

.ladi-form
  [data-form-checkout-item='payment']
  .ladi-form-checkout-payment-content
  div
  > span {
  display: block;
}

.ladi-form
  [data-form-checkout-item='payment']
  .ladi-form-item
  .ladi-form-checkout-payment-content
  div {
  display: table-cell;
  vertical-align: middle;
  padding: 0 6px;
  cursor: pointer;
  width: 100%;
  position: relative;
}

.ladi-form
  [data-form-checkout-item='payment']
  .ladi-form-item
  .ladi-form-checkout-payment-content
  div.arrow:before {
  content: '';
  --url: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M6.14645%203.14645C6.34171%202.95118%206.65829%202.95118%206.85355%203.14645L11.3536%207.64645C11.5488%207.84171%2011.5488%208.15829%2011.3536%208.35355L6.85355%2012.8536C6.65829%2013.0488%206.34171%2013.0488%206.14645%2012.8536C5.95118%2012.6583%205.95118%2012.3417%206.14645%2012.1464L10.2929%208L6.14645%203.85355C5.95118%203.65829%205.95118%203.34171%206.14645%203.14645Z%22%20fill%3D%22black%22%2F%3E%0A%3C%2Fsvg%3E');
  -webkit-mask-image: var(--url);
  mask-image: var(--url);
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: auto;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.ladi-form [data-form-checkout-item='total_price'] {
  display: flex;
  flex-flow: column;
  gap: 3px;
  justify-content: center;
}

.ladi-form [data-form-checkout-item='total_price'] .line {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.ladi-form [data-form-checkout-item='total_price'] .title-price.big {
  font-weight: bold;
  font-size: 130%;
}

.ladi-form [data-form-checkout-item='total_price'] .title-number-price {
  font-size: 115%;
}

.ladi-form [data-form-checkout-item='total_price'] .title-number-price.big {
  font-weight: bold;
  font-size: 130%;
}

.ladi-form [data-form-checkout-item='total_price'] .space {
  border-top: 1px solid;
  margin: 15px 0 10px;
}

.ladi-form [data-form-checkout-item='bump_offer'] .ladi-form-item {
  overflow: hidden;
}

.ladi-form .ladi-form-checkout-bump-offer-checkbox {
  display: flex;
  flex-flow: row;
  gap: 8px;
  padding: 8px;
  margin: 12px 8px 0 8px;
  border-radius: 8px;
  align-items: center;
  --check-size-bumpoffer: 18px;
  --width-quantity-bumpoffer: 60px;
}

.ladi-form .ladi-form-checkout-bump-offer-product.option-2 {
  display: block;
}

.ladi-form .ladi-form-checkout-bump-offer-checkbox.checkbox-bump-offer-1 {
  background-color: #3c72f9;
  margin: 0;
}

.ladi-form .ladi-form-checkout-bump-offer-checkbox.checkbox-bump-offer-1 p {
  color: #fff;
}

.ladi-form .ladi-form-checkout-bump-offer-checkbox.checkbox-bump-offer-2 {
  background-color: #fde298;
  margin: 12px 0 4px;
}

.ladi-form .ladi-form-checkout-bump-offer-checkbox.checkbox-bump-offer-3 {
  background-color: #f3f4f5;
  margin: 8px 0 0;
  padding: 4px 8px;
  width: max-content;
}

.ladi-form
  .ladi-form-checkout-bump-offer-checkbox.checkbox-bump-offer-3
  .ladi-form-checkout-bump-offer-check {
  width: 12px;
  height: 12px;
  flex: 0 0 12px;
}

.ladi-form .ladi-form-checkout-bump-offer-check {
  --border-size: 1px;
  width: var(--check-size-bumpoffer);
  height: var(--check-size-bumpoffer);
  flex: 0 0 var(--check-size-bumpoffer);
  border: var(--border-size) solid #cfd3d8;
  background-color: #fff;
  border-radius: 100%;
  display: block;
  position: relative;
}

.ladi-form .ladi-form-checkout-bump-offer-check.checked:before {
  content: '';
  width: calc(var(--check-size-bumpoffer) / 2);
  height: calc(var(--check-size-bumpoffer) / 2);
  border-radius: inherit;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(
    calc(50% - var(--border-size)),
    calc(50% - var(--border-size))
  );
}

.ladi-form .ladi-form-checkout-bump-offer-check.multiple {
  border-radius: 4px;
}

.ladi-form .ladi-form-checkout-bump-offer-check.multiple.checked:before {
  content: '';
  --url: url('data:image/svg+xml,%0A%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M18.71%207.20998C18.617%207.11625%2018.5064%207.04186%2018.3846%206.99109C18.2627%206.94032%2018.132%206.91418%2018%206.91418C17.868%206.91418%2017.7373%206.94032%2017.6154%206.99109C17.4936%207.04186%2017.383%207.11625%2017.29%207.20998L9.84%2014.67L6.71%2011.53C6.61348%2011.4367%206.49954%2011.3634%206.37468%2011.3142C6.24983%2011.265%206.1165%2011.2409%205.98232%2011.2432C5.84814%2011.2455%205.71573%2011.2743%205.59265%2011.3278C5.46957%2011.3812%205.35824%2011.4585%205.265%2011.555C5.17176%2011.6515%205.09845%2011.7654%205.04924%2011.8903C5.00004%2012.0152%204.97591%2012.1485%204.97823%2012.2827C4.98055%2012.4168%205.00928%2012.5492%205.06277%2012.6723C5.11627%2012.7954%205.19348%2012.9067%205.29%2013L9.13%2016.84C9.22296%2016.9337%209.33356%2017.0081%209.45542%2017.0589C9.57728%2017.1096%209.70799%2017.1358%209.84%2017.1358C9.97201%2017.1358%2010.1027%2017.1096%2010.2246%2017.0589C10.3464%2017.0081%2010.457%2016.9337%2010.55%2016.84L18.71%208.67998C18.8115%208.58634%2018.8925%208.47269%2018.9479%208.34619C19.0033%208.21969%2019.0319%208.08308%2019.0319%207.94498C19.0319%207.80688%2019.0033%207.67028%2018.9479%207.54378C18.8925%207.41728%2018.8115%207.30363%2018.71%207.20998Z%22%20fill%3D%22%231852FA%22%2F%3E%0A%3C%2Fsvg%3E%0A');
  -webkit-mask-image: var(--url);
  mask-image: var(--url);
  width: var(--check-size-bumpoffer);
  height: var(--check-size-bumpoffer);
  -webkit-mask-size: var(--check-size-bumpoffer);
  mask-size: var(--check-size-bumpoffer);
  pointer-events: none;
  background-color: black;
  position: absolute;
  top: -1px;
  left: -1px;
  transform: none;
}

.ladi-form
  .ladi-form-checkout-bump-offer-checkbox.checkbox-bump-offer-3
  .ladi-form-checkout-bump-offer-check.multiple.checked:before {
  top: -5px;
  left: -3px;
}

.ladi-form .ladi-form-checkout-bump-offer-product {
  display: flex;
  flex-flow: row;
  gap: 16px;
  margin: 8px 12px;
  align-items: center;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-radius: 0 !important;
}

.ladi-form .ladi-form-checkout-bump-offer-product.option-1 {
  display: block !important;
  padding-bottom: 8px;
}

.ladi-form .ladi-form-item:last-child .ladi-form-checkout-bump-offer-product {
  border: none !important;
}

.ladi-form .ladi-form-checkout-bump-offer-product .item-product {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  margin-top: 16px;
  margin-bottom: 12px;
}

.ladi-form .ladi-form-checkout-bump-offer-product .item-product:first-child {
  margin-top: 0;
}

.ladi-form .ladi-form-checkout-bump-offer-product .item-product:last-child {
  margin-bottom: 0;
}

.ladi-form .ladi-form-checkout-bump-offer-product .item-product img {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  overflow: hidden;
}

.ladi-form
  .ladi-form-checkout-bump-offer-product
  .item-product
  .item-product-title {
  font-size: 90%;
  opacity: 0.6;
}

.ladi-form
  .ladi-form-checkout-bump-offer-product
  .item-product
  .item-product-description {
  color: #9fa7b1;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ladi-form .ladi-form-checkout-bump-offer-product .item-detail {
  display: flex;
  flex-direction: column;
}

.ladi-form
  .ladi-form-checkout-bump-offer-product
  .item-detail
  .shock-promotion-label {
  background-color: #ef9300;
  width: max-content;
  border-radius: 4px;
  padding: 2px 8px;
  color: #fff;
  margin-bottom: 4px;
  font-weight: 600;
}

.ladi-form .ladi-form-checkout-bump-offer-product .item-detail .pr-name {
  font-weight: 400;
  font-size: 120%;
  line-height: 1.4;
}

.ladi-form .ladi-form-checkout-bump-offer-product .item-detail .pr-price {
  font-weight: 400;
  line-height: 1.4;
}

.ladi-form .ladi-form-checkout-bump-offer-product .item-detail span a {
  text-decoration: line-through;
  opacity: 0.6;
}

.ladi-form .ladi-form-checkout-bump-offer-product .item-detail .special {
  background-color: #e01a1a;
  padding: 2px 8px;
  margin-right: 6px;
  color: #fff;
  border-radius: 4px;
  font-size: 80%;
}

.ladi-form [data-form-checkout-item='coupon_code'] .content-discount-block {
  padding: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ladi-form [data-form-checkout-item='coupon_code'] .ladi-form-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ladi-form
  [data-form-checkout-item='coupon_code']
  .content-discount-block
  .title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ladi-form
  [data-form-checkout-item='coupon_code']
  .content-discount-block
  .title
  span {
  font-weight: 400;
  font-size: 100%;
  line-height: 1.4;
}

.ladi-form
  [data-form-checkout-item='coupon_code']
  .content-discount-block
  .title
  i {
  width: 16px;
  height: 16px;
  mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
  display: inline-block;
  mask-size: cover;
}

.ladi-form
  [data-form-checkout-item='coupon_code']
  .content-discount-block
  .placeholder {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.ladi-form
  [data-form-checkout-item='coupon_code']
  .content-discount-block
  .placeholder
  i {
  width: 16px;
  height: 16px;
  mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-left.svg);
  display: inline-block;
  mask-size: cover;
}

.ladi-form
  [data-form-checkout-item='coupon_code']
  .content-discount-block
  .placeholder
  span {
  font-weight: 400;
  font-size: 100%;
  line-height: 1.4;
}

.ladi-group {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ladi-image {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ladi-image .ladi-image-background {
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  background-attachment: scroll;
  background-origin: content-box;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.ladi-headline {
  width: 100%;
  display: inline-block;
  word-break: break-word;
  background-size: cover;
  background-position: center center;
}

.ladi-headline a {
  text-decoration: underline;
}

.ladi-line {
  position: relative;
}

.ladi-line .ladi-line-container {
  border-bottom: 0 !important;
  border-right: 0 !important;
  width: 100%;
  height: 100%;
}

a[data-action] {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

a:visited {
  color: inherit;
}

a:link {
  color: inherit;
}

[data-opacity='0'] {
  opacity: 0;
}

[data-hidden='true'] {
  display: none;
}

[data-action='true'] {
  cursor: pointer;
}

.ladi-hidden {
  display: none;
}

.ladi-animation-hidden {
  visibility: hidden !important;
  opacity: 0 !important;
}

.element-click-selected {
  cursor: pointer;
}

.is-2nd-click {
  cursor: pointer;
}

.ladi-button-shape.is-2nd-click,
.ladi-accordion-shape.is-2nd-click {
  z-index: 3;
}

.backdrop-popup {
  display: block !important;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90000060;
}

.backdrop-dropbox {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90000040;
}

.ladi-lazyload {
  background-image: none !important;
}

.ladi-list-paragraph ul li.ladi-lazyload:before {
  background-image: none !important;
}

.ladi-element.ladi-auto-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100% !important;
  left: 0 !important;
  -webkit-overflow-scrolling: touch;
}

[data-hint]:not([data-timeout-id-copied]):before,
[data-hint]:not([data-timeout-id-copied]):after {
  display: none !important;
}

.ladi-section.ladi-auto-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

/* Add styles for the audio toggle button */
.audio-toggle-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  background-color: #18392b;
  border: none; /* Xóa viền mặc định */
  border-radius: 50%; /* Làm cho nó tròn */
  padding: 15px; /* Tăng padding để nút lớn hơn */
  cursor: pointer; /* Đổi con trỏ thành pointer */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Thêm bóng cho độ sâu */
  transition: background-color 0.3s; /* Hiệu ứng chuyển màu mượt mà */
  width: 50px; /* Đặt chiều rộng */
  height: 50px; /* Đặt chiều cao */
}

.audio-toggle-button:hover {
  background-color:rgb(14, 39, 28); /* Đổi màu nền khi hover */
}

/* Add styles for the playerIcon to increase icon size and change color */
.playerIcon {
  font-size: 20px; /* Adjust this value to increase or decrease the icon size */
  color: white; /* Change icon color to white */
}

.error {
  border-color: red !important;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.visible {
  opacity: 1;
  visibility: visible;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.loading-spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
